<template>
	<div>
		<BackButton
			:route="{
				name: 'content-announcement-banners',
				label: 'Anouncement Banners',
			}"
		/>
		<h2 class="font-semibold text-lg text-red-500">WORK IN PROGRESS</h2>

		<div class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					New Anouncement Banner
				</h2>
			</div>
		</div>

		<div class="bg-white border-t border-gray-200 shadow overflow-hidden sm:rounded-lg">
			<div class="px-4 py-5">
				<FieldsTipTapEditor
					v-model="message"
					:field="{
						label: 'Anouncement Text',
						model: 'message',
						required: true,
						colSpan: 'col-span-6',
						rows: 10,
					}"
				/>

				<FieldsDatetimeRange
					v-model="dateRange"
					:field="{
						label: 'Schedule',
						model: 'dateRange',
						required: true,
						colSpan: 'col-span-6',
					}"
				/>
			</div>

			<div class="border-t border-gray-200 p-5 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
					@click="store"
				>
					<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Save
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { SaveIcon } from '@heroicons/vue/outline'

export default {
	components: {
		SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			errors: false,
			message: '',
			dateRange: {
				// start: '2021-05-11 21:00:00',
				// end: '2021-05-02 21:00:00',
			},
		}
	},
	methods: {
		store() {
			this.errors = false
			if (this.message.trim() === '') {
				this.errors = true
				this.toast.error('You must specify a message to display on the announcement banner')
			}
			if (!this.dateRange.start && !this.dateRange.end) {
				this.errors = true
				this.toast.error('You must specify a start and end date to display the announcement banner')
			}

			if (!this.errors) {
				console.log({
					start: this.dateRange.start.toISOString(),
					end: this.dateRange.end.toISOString(),
				})
				this.loaderShow()

				this.api.clinicalResearch
					.store({
						message: this.message,
						start: this.dateRange.start.toISOString(),
						end: this.dateRange.end.toISOString(),
					})
					.then(data => {
						// this.$router.push({ name: 'content-announcement-banners' })
					})
					.catch(error => {
						this.toast.error('Error creating clinical research:\n' + error.response.data.message)
					})
					.then(() => {
						this.loaderHide()
					})
			}
		},
	},
}
</script>
